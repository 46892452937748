<template>
    <Introduction :class="'background-lille'">
        <template #title>
            Лилль - город с фламандским влиянием.
        </template>
    </Introduction>

    <Main>
        <template #current>
            Лилль
        </template>

        <template #youtube>
            <iframe src="https://www.youtube.com/embed/k8J6Vsccsuo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>

        <template #pricing>
            <div class="case">
                <div class="title">
                    <h5>РЕСТОРАНЫ</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Стоимость обеда/ужина в недорогом ресторане на 1 человека - 13 евро</li>
                        <li>Стоимость обеда/ужина на двоих в ресторане среднего уровня (обед/ужин из 3-х блюд) - 40 евро</li>
                        <li>McMeal в Макдональдс - 8 евро</li>
                        <li>Капучино - 3,25 евро</li>
                        <li>Местное пиво - 5.25 (0,5 литра)</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>МАГАЗИНЫ</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Молоко (1 литр) - 1 евро</li>
                        <li>Буханка белого хлеба (500 гр) - 1,69 евро</li>
                        <li>Рис (1 кг) - 1,56 евро</li>
                        <li>Яйца (12 шт) - 3,03 евро</li>
                        <li>Местный сыр (1 кг) - 12,75 евро</li>
                        <li>Филе курицы (1 кг) - 9,68 евро</li>
                        <li>Яблоки (1 кг) - 2,41 евро</li>
                        <li>Картофель (1 кг) - 1,53 евро</li>
                        <li>Бутылка вина (средний уровень) - 6,50 евро</li>
                        <li>Местное пиво (0,5 литра) - 1,52 евро</li>
                        <li>Вода (1,5 литра) - 0,34 евро</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>ТРАНСПОРТ</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Билет в 1 конец - 1.7 евро</li>
                        <li>Абонемент на месяц - 59 евро</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>Развлечения </h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Фитнес-клуб, абонемент на месяц - 25,38 евро</li>
                        <li>Билет в кино - 11,25 евро</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>Проживание</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>1-комнатные апартаменты в центре города - 659 евро</li>
                        <li>3-комнатные апартаменты в центре города - 1467 евро</li>
                    </ul>
                </div>
            </div>
        </template>

        <template #intro>
            <p>
                Лилль - город на севере Франции, расположенный на реке Делль, в 219 км к северо-северо-востоку от Парижа и в 14 км от границы с Бельгией. Его коммерческая и промышленная деятельность стимулируется близостью к северным странам Европейского Союза и хорошим коммуникационным расположением. Это важный железнодорожный узел с высокоскоростным железнодорожным сообщением с Лондоном, Брюсселем, Парижем и другими регионами южной и западной Франции. Из Парижа до Лилля на поезде можно добраться за 1 час, из Брюсселя — около 40 минут, а из Лондона — всего 1,5 часа.
            </p>
            <p>
                Население города - более 230 тысяч человек.
            </p>
            <p>
                История города Лилль достаточно богата, а все из-за его расположения. Город осаждали 11 раз, управление Лиллем переходило то фламандцам, то испанцам, то австрийцам, то англичанам. Именно в Средневековье город стал частью Франции. В Лилле также был рожден знаменитый французский военный и государственный деятель Шарль де Голль.
            </p>
            <p>
                Лилль славится своими конфетами, вафлями и гастрономией с значительным фламандским влиянием.
            </p>
            <p>
                Многие современные методы розничной торговли, такие как продажа по почте, аутлеты от фабрик, франчайзинг и дистрибуция масс-маркета, зародились в северной Франции. Это также родина пионеров почтовых услуг, таких как La Redoute, 3 Suisses и Damart, и таких гигантов розничной торговли, как Auchan, Decathlon, Leroy Merlin, Boulanger и Norauto.
            </p>
            <p>
                В мегаполисе Лилль проживает одна из самых больших студенческих общин в стране, этот город является популярным местом для тех, кто хочет получить высшее образование.
            </p>
            <p>
                В городе доступны различные виды общественного транспорта, в том числе автобусы, трамваи и система метро без водителя.
            </p>
        </template>

        <template #logement>
            <p>1-комнатные апартаменты в центре города - 659 евро</p>
            <p>3-комнатные апартаменты в центре города - 1467 евро</p>
            <p>Районы, которые стоит избегать : Fives, Moulins, et Sud</p>
            <p>Районы, где стоит жить:  Vauban – Masséna, Gambetta – Wazemmes, Vieux-Lille</p>
        </template>

    </Main>
</template>

<script>
import Introduction from '@/components/city/slots/Introduction.vue'
import Main from '@/components/city/slots/Main.vue'
export default {
    components:{
        Introduction,
        Main
    }
}
</script>