<template>
    <div class="city">
        <Navbar/>
        <Lille/>
    </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import Lille from '@/components/city/Lille.vue'
export default {
    components: {
        Navbar,
        Lille
    }
}
</script>